@-webkit-keyframes blink {
    0%   { border-color:#fdcc00; }
    50%  { border-color:#fc8900;}
    100% { border-color:#feba00; }
}
@-moz-keyframes blink {
    0%   { border-color:#fdcc00; }
    50%  { border-color:#fc8900;}
    100% { border-color:#feba00; }
}
@-ms-keyframes blink {
    0%   { border-color:#fdcc00; }
    50%  { border-color:#fc8900;}
    100% { border-color:#feba00; }
}
#animatedBorderID {
 -webkit-animation: blink 1s infinite;
 -moz-animation:    blink 1s infinite;
 -ms-animation:     blink 1s infinite;
 border: 2.5px solid;
 border-radius: 7px;
}
