.Strengthen-refer-form-container  {
    padding: 1px 15px 15px 15px;
}
.Strengthen-refer-form-container  .success-image-conatiner {
    text-align: center;
    margin-bottom: 50px;
    margin-top: 70px;
}
.Strengthen-refer-form-container  .candidate-info-top {
    background: #fff;
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #959595;
    width: fit-content;
    position: relative;
    bottom: -.8%;
    left: 3%;
    padding: 0px 10px;
}
.Strengthen-refer-form-container  .candidate-info {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 14px;
}
.Strengthen-refer-form-container  .candidate-info .name {
    font-weight: 500;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 16px;
    letter-spacing: 0.4px;
}
.Strengthen-refer-form-container  .candidate-info .email {
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #959595;
}
.Strengthen-refer-form-container  .candidate-info .social-links {
    display: flex;
}
.Strengthen-refer-form-container  .candidate-info .social-links .resume {
    border: 1px solid #444;
    padding: 4px;
    border-radius: 43px;
    width: fit-content;
    margin-left: 4px;
}
.Strengthen-refer-form-container  .employer-referral-top {
    background: #fff;
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #959595;
    width: fit-content;
    position: relative;
    bottom: -1%;
    left: 6%;
    padding: 2px;
}
.Strengthen-refer-form-container  .employer-referral {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 14px;
    margin-bottom: 8px;
}
.Strengthen-refer-form-container  .employer-message-top {
    background: #fff;
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #959595;
    width: fit-content;
    position: relative;
    bottom: -1%;
    left: 6%;
    padding: 2px;
}
.Strengthen-refer-form-container  .employer-message {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 14px;
    color: #959595;
}
.Strengthen-refer-form-container  .question-text {
    font-weight: 500;
    font-size: 14px;
    font-family: Roboto-Regular;
    line-height: 16px;
    margin-top: 10px;
    margin-bottom: 8px;
}
.Strengthen-refer-form-container  .question-text .submitted-question {
    font-weight: 600;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 16px;
}
.Strengthen-refer-form-container  .question-text .submitted-question .answer {
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
}
.Strengthen-refer-form-container  .text-input {
    margin-top: 0px;
    width: 100%;
}
.Strengthen-refer-form-container  .strength-bar .strength-name {
    font-weight: 500;
    font-size: 16px;
    font-family: Roboto-Regular;
    line-height: 19px;
    margin-bottom: 8px;
    margin-top: 30px;
}
.Strengthen-refer-form-container  .strength-bar .strength-sub {
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 18px;
    color: #444;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
}
.Strengthen-refer-form-container  .strength-bar .strength-sub .progress-bar {
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 18px;
    text-align: right;
    color: #0fbc71;
}
.Strengthen-refer-form-container  .diversity-name {
    font-weight: 400;
    font-size: 14px;
    font-family: Roboto-Regular;
    line-height: 16px;
    color: #444;
}
.org-info{
    display: flex;   
    padding-top: 12px;
    padding-left: 20px;
}
.org-info .org-detail{
    display: flex;
    flex-direction: column;
}
.org-info img{
    height: 44px;
    width: 44px;
    margin-right: 9px;
}
.org-info .org-detail .name{
    font-weight: 500;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-top: 3px;
}
.org-info .org-detail .email {
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #959595;
    margin-top: 3px;
}
.candidateRating .MuiFormGroup-root{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.candidateRating .MuiFormGroup-root label{
    display: flex;
    flex-direction: column;
}
.candidateRating .MuiFormGroup-root .MuiFormControlLabel-root{
    margin-right: -11px !important;
}
.candidateRating-types{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3px 5px 3px 5px;
}
.candidateRating-types span{
    font-weight: 400;
    font-size: 14px;
    font-family: Roboto-Regular;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #959595;
}
.MuiInput-input{
    padding: 8px 10px !important;
}
.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
    margin: 20px 0px 30px 0px;
  }
.btn-apply-disabled {
    margin-left: 10px !important;
    text-transform: unset !important;
    height: 40px !important;
    width: 163px !important;
    color: #FFF !important;
}
.btn-apply {
    margin-left: 10px !important;
    text-transform: unset !important;
    height: 40px !important;
    width: 163px !important;
    background-color: #0FBC71 !important;
    border: 1px solid #0FBC71 !important;
    color: #FFF !important;
}
.btn-refer {
    /* margin-left: 10px !important; */
    text-transform: unset !important;
    height: 40px !important;
    width: 182px !important;
    background-color: #FFF !important;
    border: 1px solid #0FBC71 !important;
    color: #0FBC71 !important;
}
.sendAnotherReferal-button {
    margin-left: 10px !important;
    text-transform: unset !important;
    height: 40px !important;
    background-color: #0FBC71 !important;
    border: 1px solid #0FBC71 !important;
    color: #FFF !important;
}
.close-button {
    /* margin-left: 10px !important; */
    text-transform: unset !important;
    height: 40px !important;
    width: 163px !important;
    background-color: #FFF !important;
    border: 1px solid #0FBC71 !important;
    color: #0FBC71 !important;
}
.switch-tabs-wrapper{
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 21px 0px 5px 0px;
}
.switch-tabs-wrapper svg{
    color: #bfbfbf;
    font-size: 12px;
    cursor: pointer;
}
.hr-footer{
    border-top: 1px solid #EEEEEE;
    margin: 22px 0px;
}
.Strengthen-refer-form-container  .question-text .pdf-files-parent {
    display: flex;
}
.Strengthen-refer-form-container  .question-text .pdf-files-parent h6 {
    font-family: Roboto-Regular;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.4000000059604645px;
    padding-right: 5px;
    width: 80px;
    margin: 0;
}
.Strengthen-refer-form-container  .question-text .pdf-files-parent .pdf-child {
    font-family: Roboto-Regular;
    display: flex;
    flex-direction: column;
}
.Strengthen-refer-form-container  .question-text .pdf-files-parent .pdf-child .pdf-item {
    font-family: Roboto-Regular;
    border: 1px solid #444444;
    padding: 2px 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    font-size: 11px;
    vertical-align: middle;
    display: inherit;
}
.Strengthen-refer-form-container  .question-text .pdf-files-parent .pdf-child .pdf-item svg {
    padding-right: 3px;
}

.Strengthen-refer-form-container  .question-text  .textWrap {
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    width: 300px;
    overflow-wrap: break-word;
}