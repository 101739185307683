
.crop-image-buttons{
    font-size: 12px;
    height: 32px;
    color: #fff;
    font-weight: bold;
    background-color: #0FBC71;
    border: none;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;
}
