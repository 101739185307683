.react-tel-input .special-label {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
    left: 7px !important;
}
.react-tel-input .country-list {
    width: 335px !important;
    max-height: 335px !important;
}
.react-tel-input .country-list .search {
    width: 100%;
}

.react-tel-input .country-list .search-box {
    width: 95%;
}

.react-tel-input .country-list .search-box {
    padding-right: 25px;
    background: url(../../assets/img/search_icon.png) no-repeat right;
    background-size: 15px;
    opacity: 0.8;
  }

.react-tel-input ::-webkit-scrollbar {
    width: 3px;
}

.react-tel-input ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0;
    border-radius: 10px;
}

.react-tel-input ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    height: 50px;
}
