#referPhoneNo .special-label {
  color: #CCC;
  font-size: 12px;
  left: 10px;
}

#referPhoneNo .form-control {
  width: 100%;
  padding: 12px 14px 12px 58px;
  font-size: 14px;
  border: 1px solid #ccc;
}

#referPhoneNo .form-control:focus{
  box-shadow: 0 0 0 0;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.5);
}

#referPhoneNo{
  border-style:none;
  z-index: 39;
}

#referPhoneNo .react-tel-input .form-control.invalid-number:focus {
  border: 2px solid #f44336 !important;
}

#referPhoneNo .react-tel-input .form-control.invalid-number {
  border: 1px solid #f44336 !important;

}

.labelForInvalidNumber .special-label{
  color: #f44336 !important;
  font-size: 12px;
  left: 10px;
}
