#ql-editor  p{
    font-size: 0.9rem !important;
    color: #727272 !important;
    margin: 0 !important;
    line-height: 1.5em;
}
#ql-editor  p strong{
    font-size: 0.9rem !important;
    color: #727272 !important;
    line-height: 1.5em;
}
#ql-editor  h1{
    font-size: 19px!important;
    color: #959595 !important;
    margin: 7px 0px !important;
    line-height: 1.5em;
}
#ql-editor  ul li, #ql-editor  ol li{
    font-size: 0.9rem !important;
    color: #727272 !important;
    margin: 3px 0px !important;
    line-height: 1.5em;
}
#ql-editor  ul, #ql-editor  ol {
    padding-left: 15px!important;
    margin: 0 0 0 10px !important;
}
#ql-editor  h2{
    font-size: 17px!important;
    color: #959595 !important;
    margin: 7px 0px !important;
    line-height: 1.5em;
}
#ql-editor  a{
    text-decoration: underline !important;
    color: #1d9bd1 !important;
}
/* #ql-editor  p span{
    font-size: 0.9rem !important;
    color: #727272 !important;
    line-height: 1.5em;
}
#ql-editor  h1 span{
    font-size: 19px!important;
    color: #959595 !important;
    line-height: 1.5em;
}
#ql-editor  ul li span, #ql-editor  ol li span{
    font-size: 0.9rem !important;
    color: #727272 !important;
    line-height: 1.5em;

}
#ql-editor  h2 span{
    font-size: 17px!important;
    color: #959595 !important;
    line-height: 1.5em;

}
#ql-editor  a span{
    text-decoration: underline !important;
    color: #1d9bd1 !important;
} */
/* #ql-editor p + ol{
    margin-top: -16px !important;
} */
#org-wrapper div div div div input{
    display: none !important;
}
