input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}
