.redux-select-latest-container {
  position: relative;
  z-index: 2; 
  font-family: Roboto-Regular;

}
.select-label {
  position: absolute;
  top: -7px;
  left: 8px;
  z-index: 1;
  background: #fff;
  color: #969696;
  font-size: 12px;
  padding: 0px 6px;
}

.option-checkbox {
  width: 24px;
  height: 14px;
}
.MuiSvgIcon-fontSizeSmall {
  font-size: 16px;
} 
.Select-multi-value-wrapper {
  display: flex;
  flex-wrap: wrap;  
}
.CustomOption__container {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.Select-value {
  order: 1;
  margin-left: 0;
  margin-right: 5px;
  margin-top: 5px;
  position: relative;
  border: 1px solid #444444;
  border-radius: 25px;
  background-color: #0FBC7105;
  display: inline-block;
  font-size: 0.9em;
  padding: 0px 24px 0px 8px;
  vertical-align: top;
  max-width: 100%;
}
.Select-value-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  color: #444444 !important;
  top: 0px;
  right: 5px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  padding: 0;
  border: none;
  font-size: 17px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}

.Select-value-label {
  padding: 0;
  font-size: 12px;
  color: #444444;
  line-height: 16px;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  cursor: default;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
 