.SSO-Questions-wrapper > button {
    box-shadow: none !important;
    padding: 2px !important;
    border-radius: 20px !important;
    border: 1px solid #ececec !important;
}
.SSO-Questions-wrapper > button > div {
    margin-right: 0px !important;
    padding: 5px !important;
    border-radius: 15px !important;
    height: 18px;
    width: 18px;
}

.SSO-Questions-wrapper > button > span {
    display: none !important;
}
