.Strengthen-job-form-container .success-image-conatiner {
    text-align: center;
    margin-bottom: 50px;
    margin-top: 70px;
}
.Strengthen-job-form-container .candidate-info-top {
    background: #fff;
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #959595;
    width: fit-content;
    position: relative;
    bottom: -1%;
    left: 6%;
    padding: 2px;
}
.Strengthen-job-form-container .candidate-info {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 14px;
    margin-bottom: 8px;
}
.Strengthen-job-form-container .candidate-info .name {
    font-weight: 500;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-bottom: 8px;
}
.Strengthen-job-form-container .candidate-info .email {
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #959595;
    margin-bottom: 8px;
}
.Strengthen-job-form-container .candidate-info .social-links {
    display: flex;
}
.Strengthen-job-form-container .candidate-info .social-links .resume {
    border: 1px solid #444;
    padding: 4px;
    border-radius: 43px;
    width: fit-content;
    margin-left: 4px;
}
.Strengthen-job-form-container .employer-referral-top {
    background: #fff;
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #959595;
    width: fit-content;
    position: relative;
    bottom: -1%;
    left: 6%;
    padding: 2px;
}
.Strengthen-job-form-container .employer-referral {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 14px;
    margin-bottom: 8px;
}
.Strengthen-job-form-container .employer-message-top {
    background: #fff;
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #959595;
    width: fit-content;
    position: relative;
    bottom: -1%;
    left: 6%;
    padding: 2px;
}
.Strengthen-job-form-container .employer-message {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 14px;
    margin-bottom: 20px;
    color: #959595;
}
.Strengthen-job-form-container .question-text {
    font-weight: 500;
    font-size: 14px;
    font-family: Roboto-Regular;
    line-height: 16px;
    margin-top: 18px;
    margin-bottom: 8px;
}
.Strengthen-job-form-container .question-text .submitted-question {
    font-weight: 600;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 16px;
}
.Strengthen-job-form-container .question-text .submitted-question .answer {
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
}
.Strengthen-job-form-container .text-input {
    margin-top: 0px;
    width: 100%;
}
.Strengthen-job-form-container .strength-bar .strength-name {
    font-weight: 500;
    font-size: 16px;
    font-family: Roboto-Regular;
    line-height: 19px;
    margin-bottom: 8px;
}
.Strengthen-job-form-container .strength-bar .strength-sub {
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 18px;
    color: #444;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
}
.Strengthen-job-form-container .strength-bar .strength-sub .progress-bar {
    font-weight: 400;
    font-size: 12px;
    font-family: Roboto-Regular;
    line-height: 18px;
    text-align: right;
    color: #0fbc71;
}
.Strengthen-job-form-container .diversity-name {
    font-weight: 400;
    font-size: 14px;
    
    line-height: 16px;
    color: #444;
}
.resume-icon {
    color: rgb(117, 117, 117);
    padding: 2px 4px 2px 4px;
    border: 1px solid black;
    border-radius: 4px;
    margin-left: 4px;
    font-size: 16px !important;
}
.complete-refer-strength-form-button{
    font-family: Roboto-Regular;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.4000000059604645px;
    text-decoration: underline;
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 10px 0px 0px 0px;
    padding: 0;
}
.strength-bar  {
    margin-bottom: 18px;
}
.license-selector .MuiSelect-root {
    padding: 12px;
}

.license-selector .MuiSelect-select:focus {
    background-color: white;
}
