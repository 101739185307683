.strength_form_step_1_wrapper h3{
    font-family: Roboto-Regular;
    margin: 0px 0px 4px 4px;
    font-size: 14px;
    font-weight: 550;
    padding: 0;
}
.strength_form_step_2_wrapper{
    padding-top: 10px;
}
.files_upload_wrapper{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px 0px 4px 0px
}
.files_upload_child{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.files_upload_wrapper svg{
    color: #757575;
}
.files_upload_wrapper div svg{
    color: #9A9A9A;
    cursor: pointer;
}
.files_upload_child span{
    font-family: Roboto-Regular;
    font-size: 14px;
    font-weight: 550;
    margin: 4px 5px 0px 15px;
}
.boon_field_parent{
    padding: 0px;
}
.boon_field_parent div{
    height: 47px;
}
.boon_field_parent div label{
    top: -3px !important;
    color: #C2C2C2;
}
.links-div div .MuiInputBase-root .MuiInput-input{
    padding-left: 0!important;
}
.strength_form_step_1_wrapper hr{
    border-top: 1px solid #EEEEEE;
    margin: 22px 0px;
}
.strength_form_step_1_wrapper h4{
    font-family: Roboto-Regular;
    margin: 0px 0px 4px 0px;
    font-size: 14px;
    font-weight: 550;
    padding: 0;
}
.boon_field_wrapper button{
    padding: 16px !important;
}
.boon_field_wrapper{
    padding-bottom: 10px;
}
.worked-with-person-directly{
    flex-direction: row !important;
    justify-content: space-between !important;
    width: 206px;
}
.know-professionally-personally{
    flex-direction: row !important;
    justify-content: space-between !important;
    width: 340px;
}
.diversityName{
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}
